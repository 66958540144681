.paginationBttns {
    width: 100%;
    height: 20px;
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 1rem 0 1.2rem 0;
  }
  
  .paginationBttns a {
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid teal;
    color: teal;
    cursor: pointer;
  }
  
  .paginationBttns a:hover {
    color: white;
    background-color: teal;
  }
  
  .paginationActive a {
    color: white;
    background-color: teal;
  }
  
  .paginationDisabled a {
    color: grey;
    cursor: not-allowed;
  }