.header {
    position: fixed;
    height: 60px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    background-color: #314641;
}

.header .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1300px;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
}